import React, { useState, useEffect } from 'react';
import { getAdminConfigVar, Offer } from '@avicennapharmacy/managemymeds-shared';
import { BulkEditModalProps } from './BulkEditModal';
import Axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select, { ValueType } from 'react-select';
import { Alert, Button, Spinner } from 'react-bootstrap';

type OffersProps = {
  pharmacyIds: string[];
  setShow: (show: boolean) => void;
};

export default ({ pharmacyIds, setShow }: OffersProps) => {
  const [offers, setOffers] = useState<Offer[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [pharmacyOffersAction, setPharmacyOffersAction] = useState(false);
  const [offersByIds, setOffersByIds] = useState<any[]>([]);

  const availableOffers = offers.map(offer => ({
    value: offer.id,
    label: offer.title
  }));

  const offersSelected = availableOffers.filter(offer => offersByIds.includes(offer.value));

  type OptionType = {
    label: string;
    value: string;
  };

  const handleOfferChange = (selected: OptionType) => {
    setOffersByIds(Array.isArray(selected) ? selected.map(offer => offer.value) : [selected.value]);
  };

  const fetchOffers = async () => {
    try {
      setLoading(true);
      setError(null);
      const offersRes = await Axios.get(`${getAdminConfigVar('pharmacyOffersEndpoint')}`);
      setOffers(offersRes.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError('Failed to load offers');
    }
  };

  useEffect(() => {
    fetchOffers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {error && <Alert variant="danger">Error loading offers</Alert>}
      <Row className="mt-3">
        <Col sm={4}>
          <h4 className="mb-4">Offers Added</h4>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={8}>
          {loading ? (
            <Spinner animation="border" />
          ) : (
            <Select
              isMulti
              options={availableOffers}
              value={offersSelected}
              onChange={(selectedOption: ValueType<OptionType>) => {
                const offer = selectedOption as OptionType;
                handleOfferChange(offer);
              }}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col sm={2}>
          <p>
            <Button className="mt-2" onClick={() => setPharmacyOffersAction(true)}>
              Save Offers
            </Button>
          </p>
          {pharmacyOffersAction && (
            <>
              Are you sure?{' '}
              <Button
                variant="danger"
                onClick={async () => {
                  setLoading(true);
                  await Axios.post(getAdminConfigVar('bulkUpdateOffersEndpoint'), {
                    pharmacyIds,
                    offersByIds
                  });
                  setLoading(false);
                  setShow(false);
                }}
              >
                Yes
              </Button>{' '}
              <Button variant="secondary" onClick={() => setPharmacyOffersAction(false)}>
                No
              </Button>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};
